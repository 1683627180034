:root {
  --pico-font-family-emoji: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --pico-font-family-sans-serif: system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, Helvetica, Arial, "Helvetica Neue", sans-serif, var(--pico-font-family-emoji);
  --pico-font-family-monospace: ui-monospace, SFMono-Regular, "SF Mono", Menlo, Consolas, "Liberation Mono", monospace, var(--pico-font-family-emoji);
  --pico-font-family: var(--pico-font-family-sans-serif);
  --pico-line-height: 1.5;
  --pico-font-weight: 400;
  --pico-font-size: 100%;
  --pico-text-underline-offset: .1rem;
  --pico-border-radius: .25rem;
  --pico-border-width: .0625rem;
  --pico-outline-width: .125rem;
  --pico-transition: .2s ease-in-out;
  --pico-spacing: 1rem;
  --pico-typography-spacing-vertical: 1rem;
  --pico-block-spacing-vertical: var(--pico-spacing);
  --pico-block-spacing-horizontal: var(--pico-spacing);
  --pico-grid-column-gap: var(--pico-spacing);
  --pico-grid-row-gap: var(--pico-spacing);
  --pico-form-element-spacing-vertical: .75rem;
  --pico-form-element-spacing-horizontal: 1rem;
  --pico-group-box-shadow: 0 0 0 #0000;
  --pico-group-box-shadow-focus-with-button: 0 0 0 var(--pico-outline-width) var(--pico-primary-focus);
  --pico-group-box-shadow-focus-with-input: 0 0 0 .0625rem var(--pico-form-element-border-color);
  --pico-modal-overlay-backdrop-filter: blur(.375rem);
  --pico-nav-element-spacing-vertical: 1rem;
  --pico-nav-element-spacing-horizontal: .5rem;
  --pico-nav-link-spacing-vertical: .5rem;
  --pico-nav-link-spacing-horizontal: .5rem;
  --pico-nav-breadcrumb-divider: ">";
  --pico-icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --pico-icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --pico-icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --pico-icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  --pico-icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  --pico-icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  --pico-icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  --pico-icon-loading: url("data:image/svg+xml,%3Csvg fill='none' height='24' width='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cstyle%3E g %7B animation: rotate 2s linear infinite; transform-origin: center center; %7D circle %7B stroke-dasharray: 75,100; stroke-dashoffset: -5; animation: dash 1.5s ease-in-out infinite; stroke-linecap: round; %7D @keyframes rotate %7B 0%25 %7B transform: rotate(0deg); %7D 100%25 %7B transform: rotate(360deg); %7D %7D @keyframes dash %7B 0%25 %7B stroke-dasharray: 1,100; stroke-dashoffset: 0; %7D 50%25 %7B stroke-dasharray: 44.5,100; stroke-dashoffset: -17.5; %7D 100%25 %7B stroke-dasharray: 44.5,100; stroke-dashoffset: -62; %7D %7D %3C/style%3E%3Cg%3E%3Ccircle cx='12' cy='12' r='10' fill='none' stroke='rgb(136, 145, 164)' stroke-width='4' /%3E%3C/g%3E%3C/svg%3E");
}

@media (width >= 576px) {
  :root {
    --pico-font-size: 106.25%;
  }
}

@media (width >= 768px) {
  :root {
    --pico-font-size: 112.5%;
  }
}

@media (width >= 1024px) {
  :root {
    --pico-font-size: 118.75%;
  }
}

@media (width >= 1280px) {
  :root {
    --pico-font-size: 125%;
  }
}

@media (width >= 1536px) {
  :root {
    --pico-font-size: 131.25%;
  }
}

a, a.secondary, a.contrast {
  --pico-text-decoration: underline;
}

small {
  --pico-font-size: .875em;
}

h1, h2, h3, h4, h5, h6 {
  --pico-font-weight: 700;
}

h1 {
  --pico-font-size: 2rem;
  --pico-line-height: 1.125;
  --pico-typography-spacing-top: 3rem;
}

h2 {
  --pico-font-size: 1.75rem;
  --pico-line-height: 1.15;
  --pico-typography-spacing-top: 2.625rem;
}

h3 {
  --pico-font-size: 1.5rem;
  --pico-line-height: 1.175;
  --pico-typography-spacing-top: 2.25rem;
}

h4 {
  --pico-font-size: 1.25rem;
  --pico-line-height: 1.2;
  --pico-typography-spacing-top: 1.874rem;
}

h5 {
  --pico-font-size: 1.125rem;
  --pico-line-height: 1.225;
  --pico-typography-spacing-top: 1.6875rem;
}

h6 {
  --pico-font-size: 1rem;
  --pico-line-height: 1.25;
  --pico-typography-spacing-top: 1.5rem;
}

thead th, thead td, tfoot th, tfoot td {
  --pico-font-weight: 600;
  --pico-border-width: .1875rem;
}

pre, code, kbd, samp {
  --pico-font-family: var(--pico-font-family-monospace);
}

kbd {
  --pico-font-weight: bolder;
}

input:not([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]), :where(select, textarea) {
  --pico-outline-width: .0625rem;
}

[type="search"] {
  --pico-border-radius: 5rem;
}

[type="checkbox"], [type="radio"] {
  --pico-border-width: .125rem;
}

[type="checkbox"][role="switch"] {
  --pico-border-width: .1875rem;
}

details.dropdown summary:not([role="button"]) {
  --pico-outline-width: .0625rem;
}

nav details.dropdown summary:focus-visible {
  --pico-outline-width: .125rem;
}

[role="search"] {
  --pico-border-radius: 5rem;
}

[role="search"]:has(button.secondary:focus, [type="submit"].secondary:focus, [type="button"].secondary:focus, [role="button"].secondary:focus), [role="group"]:has(button.secondary:focus, [type="submit"].secondary:focus, [type="button"].secondary:focus, [role="button"].secondary:focus) {
  --pico-group-box-shadow-focus-with-button: 0 0 0 var(--pico-outline-width) var(--pico-secondary-focus);
}

[role="search"]:has(button.contrast:focus, [type="submit"].contrast:focus, [type="button"].contrast:focus, [role="button"].contrast:focus), [role="group"]:has(button.contrast:focus, [type="submit"].contrast:focus, [type="button"].contrast:focus, [role="button"].contrast:focus) {
  --pico-group-box-shadow-focus-with-button: 0 0 0 var(--pico-outline-width) var(--pico-contrast-focus);
}

[role="search"] button, [role="search"] [type="submit"], [role="search"] [type="button"], [role="search"] [role="button"], [role="group"] button, [role="group"] [type="submit"], [role="group"] [type="button"], [role="group"] [role="button"] {
  --pico-form-element-spacing-horizontal: 2rem;
}

details summary[role="button"]:not(.outline):after, [aria-busy="true"]:not(input, select, textarea):is(button, [type="submit"], [type="button"], [type="reset"], [role="button"]):not(.outline):before {
  filter: brightness(0) invert();
}

[data-theme="light"], :root:not([data-theme="dark"]) {
  --pico-background-color: #fff;
  --pico-color: #373c44;
  --pico-text-selection-color: #8191b540;
  --pico-muted-color: #646b79;
  --pico-muted-border-color: #e7eaf0;
  --pico-primary: #5d6b89;
  --pico-primary-background: #525f7a;
  --pico-primary-border: var(--pico-primary-background);
  --pico-primary-underline: #5d6b8980;
  --pico-primary-hover: #48536b;
  --pico-primary-hover-background: #48536b;
  --pico-primary-hover-border: var(--pico-primary-hover-background);
  --pico-primary-hover-underline: var(--pico-primary-hover);
  --pico-primary-focus: #8191b580;
  --pico-primary-inverse: #fff;
  --pico-secondary: #5d6b89;
  --pico-secondary-background: #525f7a;
  --pico-secondary-border: var(--pico-secondary-background);
  --pico-secondary-underline: #5d6b8980;
  --pico-secondary-hover: #48536b;
  --pico-secondary-hover-background: #48536b;
  --pico-secondary-hover-border: var(--pico-secondary-hover-background);
  --pico-secondary-hover-underline: var(--pico-secondary-hover);
  --pico-secondary-focus: #5d6b8940;
  --pico-secondary-inverse: #fff;
  --pico-contrast: #181c25;
  --pico-contrast-background: #181c25;
  --pico-contrast-border: var(--pico-contrast-background);
  --pico-contrast-underline: #181c2580;
  --pico-contrast-hover: #000;
  --pico-contrast-hover-background: #000;
  --pico-contrast-hover-border: var(--pico-contrast-hover-background);
  --pico-contrast-hover-underline: var(--pico-secondary-hover);
  --pico-contrast-focus: #5d6b8940;
  --pico-contrast-inverse: #fff;
  --pico-box-shadow: .0145rem .029rem .174rem #8191b504, .0335rem .067rem .402rem #8191b506, .0625rem .125rem .75rem #8191b508, .1125rem .225rem 1.35rem #8191b509, .2085rem .417rem 2.502rem #8191b50b, .5rem 1rem 6rem #8191b50f, 0 0 0 .0625rem #8191b504;
  --pico-h1-color: #2d3138;
  --pico-h2-color: #373c44;
  --pico-h3-color: #424751;
  --pico-h4-color: #4d535e;
  --pico-h5-color: #5c6370;
  --pico-h6-color: #646b79;
  --pico-mark-background-color: #fde7c0;
  --pico-mark-color: #0f1114;
  --pico-ins-color: #1d6a54;
  --pico-del-color: #883935;
  --pico-blockquote-border-color: var(--pico-muted-border-color);
  --pico-blockquote-footer-color: var(--pico-muted-color);
  --pico-button-box-shadow: 0 0 0 #0000;
  --pico-button-hover-box-shadow: 0 0 0 #0000;
  --pico-table-border-color: var(--pico-muted-border-color);
  --pico-table-row-stripped-background-color: #6f78870a;
  --pico-code-background-color: #f3f5f7;
  --pico-code-color: #646b79;
  --pico-code-kbd-background-color: var(--pico-color);
  --pico-code-kbd-color: var(--pico-background-color);
  --pico-form-element-background-color: #fbfcfc;
  --pico-form-element-selected-background-color: #dfe3eb;
  --pico-form-element-border-color: #cfd5e2;
  --pico-form-element-color: #23262c;
  --pico-form-element-placeholder-color: var(--pico-muted-color);
  --pico-form-element-active-background-color: #fff;
  --pico-form-element-active-border-color: var(--pico-primary-border);
  --pico-form-element-focus-color: var(--pico-primary-border);
  --pico-form-element-disabled-opacity: .5;
  --pico-form-element-invalid-border-color: #b86a6b;
  --pico-form-element-invalid-active-border-color: #c84f48;
  --pico-form-element-invalid-focus-color: var(--pico-form-element-invalid-active-border-color);
  --pico-form-element-valid-border-color: #4c9b8a;
  --pico-form-element-valid-active-border-color: #279977;
  --pico-form-element-valid-focus-color: var(--pico-form-element-valid-active-border-color);
  --pico-switch-background-color: #bfc7d9;
  --pico-switch-checked-background-color: var(--pico-primary-background);
  --pico-switch-color: #fff;
  --pico-switch-thumb-box-shadow: 0 0 0 #0000;
  --pico-range-border-color: #dfe3eb;
  --pico-range-active-border-color: #bfc7d9;
  --pico-range-thumb-border-color: var(--pico-background-color);
  --pico-range-thumb-color: var(--pico-secondary-background);
  --pico-range-thumb-active-color: var(--pico-primary-background);
  --pico-accordion-border-color: var(--pico-muted-border-color);
  --pico-accordion-active-summary-color: var(--pico-primary-hover);
  --pico-accordion-close-summary-color: var(--pico-color);
  --pico-accordion-open-summary-color: var(--pico-muted-color);
  --pico-card-background-color: var(--pico-background-color);
  --pico-card-border-color: var(--pico-muted-border-color);
  --pico-card-box-shadow: var(--pico-box-shadow);
  --pico-card-sectioning-background-color: #fbfcfc;
  --pico-dropdown-background-color: #fff;
  --pico-dropdown-border-color: #eff1f4;
  --pico-dropdown-box-shadow: var(--pico-box-shadow);
  --pico-dropdown-color: var(--pico-color);
  --pico-dropdown-hover-background-color: #eff1f4;
  --pico-loading-spinner-opacity: .5;
  --pico-modal-overlay-background-color: #e8eaedbf;
  --pico-progress-background-color: #dfe3eb;
  --pico-progress-color: var(--pico-primary-background);
  --pico-tooltip-background-color: var(--pico-contrast-background);
  --pico-tooltip-color: var(--pico-contrast-inverse);
  --pico-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(76, 155, 138)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --pico-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(200, 79, 72)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
}

[data-theme="light"] input:is([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]), :root:not([data-theme="dark"]) input:is([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]) {
  --pico-form-element-focus-color: var(--pico-primary-focus);
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --pico-background-color: #13171f;
    --pico-color: #c2c7d0;
    --pico-text-selection-color: #909ebe30;
    --pico-muted-color: #7b8495;
    --pico-muted-border-color: #202632;
    --pico-primary: #909ebe;
    --pico-primary-background: #525f7a;
    --pico-primary-border: var(--pico-primary-background);
    --pico-primary-underline: #909ebe80;
    --pico-primary-hover: #b0b9d0;
    --pico-primary-hover-background: #5d6b89;
    --pico-primary-hover-border: var(--pico-primary-hover-background);
    --pico-primary-hover-underline: var(--pico-primary-hover);
    --pico-primary-focus: #909ebe60;
    --pico-primary-inverse: #fff;
    --pico-secondary: #969eaf;
    --pico-secondary-background: #525f7a;
    --pico-secondary-border: var(--pico-secondary-background);
    --pico-secondary-underline: #969eaf80;
    --pico-secondary-hover: #b3b9c5;
    --pico-secondary-hover-background: #5d6b89;
    --pico-secondary-hover-border: var(--pico-secondary-hover-background);
    --pico-secondary-hover-underline: var(--pico-secondary-hover);
    --pico-secondary-focus: #909ebe40;
    --pico-secondary-inverse: #fff;
    --pico-contrast: #dfe3eb;
    --pico-contrast-background: #eff1f4;
    --pico-contrast-border: var(--pico-contrast-background);
    --pico-contrast-underline: #dfe3eb80;
    --pico-contrast-hover: #fff;
    --pico-contrast-hover-background: #fff;
    --pico-contrast-hover-border: var(--pico-contrast-hover-background);
    --pico-contrast-hover-underline: var(--pico-contrast-hover);
    --pico-contrast-focus: #cfd5e240;
    --pico-contrast-inverse: #000;
    --pico-box-shadow: .0145rem .029rem .174rem #07090c04, .0335rem .067rem .402rem #07090c06, .0625rem .125rem .75rem #07090c08, .1125rem .225rem 1.35rem #07090c09, .2085rem .417rem 2.502rem #07090c0b, .5rem 1rem 6rem #07090c0f, 0 0 0 .0625rem #07090c04;
    --pico-h1-color: #f0f1f3;
    --pico-h2-color: #e0e3e7;
    --pico-h3-color: #c2c7d0;
    --pico-h4-color: #b3b9c5;
    --pico-h5-color: #a4acba;
    --pico-h6-color: #8891a4;
    --pico-mark-background-color: #014063;
    --pico-mark-color: #fff;
    --pico-ins-color: #62af9a;
    --pico-del-color: #ce7e7b;
    --pico-blockquote-border-color: var(--pico-muted-border-color);
    --pico-blockquote-footer-color: var(--pico-muted-color);
    --pico-button-box-shadow: 0 0 0 #0000;
    --pico-button-hover-box-shadow: 0 0 0 #0000;
    --pico-table-border-color: var(--pico-muted-border-color);
    --pico-table-row-stripped-background-color: #6f78870a;
    --pico-code-background-color: #1a1f28;
    --pico-code-color: #8891a4;
    --pico-code-kbd-background-color: var(--pico-color);
    --pico-code-kbd-color: var(--pico-background-color);
    --pico-form-element-background-color: #1c212c;
    --pico-form-element-selected-background-color: #2a3140;
    --pico-form-element-border-color: #2a3140;
    --pico-form-element-color: #e0e3e7;
    --pico-form-element-placeholder-color: #8891a4;
    --pico-form-element-active-background-color: #1a1f28;
    --pico-form-element-active-border-color: var(--pico-primary-border);
    --pico-form-element-focus-color: var(--pico-primary-border);
    --pico-form-element-disabled-opacity: .5;
    --pico-form-element-invalid-border-color: #964a50;
    --pico-form-element-invalid-active-border-color: #b7403b;
    --pico-form-element-invalid-focus-color: var(--pico-form-element-invalid-active-border-color);
    --pico-form-element-valid-border-color: #2a7b6f;
    --pico-form-element-valid-active-border-color: #16896a;
    --pico-form-element-valid-focus-color: var(--pico-form-element-valid-active-border-color);
    --pico-switch-background-color: #333c4e;
    --pico-switch-checked-background-color: var(--pico-primary-background);
    --pico-switch-color: #fff;
    --pico-switch-thumb-box-shadow: 0 0 0 #0000;
    --pico-range-border-color: #202632;
    --pico-range-active-border-color: #2a3140;
    --pico-range-thumb-border-color: var(--pico-background-color);
    --pico-range-thumb-color: var(--pico-secondary-background);
    --pico-range-thumb-active-color: var(--pico-primary-background);
    --pico-accordion-border-color: var(--pico-muted-border-color);
    --pico-accordion-active-summary-color: var(--pico-primary-hover);
    --pico-accordion-close-summary-color: var(--pico-color);
    --pico-accordion-open-summary-color: var(--pico-muted-color);
    --pico-card-background-color: #181c25;
    --pico-card-border-color: var(--pico-card-background-color);
    --pico-card-box-shadow: var(--pico-box-shadow);
    --pico-card-sectioning-background-color: #1a1f28;
    --pico-dropdown-background-color: #181c25;
    --pico-dropdown-border-color: #202632;
    --pico-dropdown-box-shadow: var(--pico-box-shadow);
    --pico-dropdown-color: var(--pico-color);
    --pico-dropdown-hover-background-color: #202632;
    --pico-loading-spinner-opacity: .5;
    --pico-modal-overlay-background-color: #08090abf;
    --pico-progress-background-color: #202632;
    --pico-progress-color: var(--pico-primary-background);
    --pico-tooltip-background-color: var(--pico-contrast-background);
    --pico-tooltip-color: var(--pico-contrast-inverse);
    --pico-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(42, 123, 111)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    --pico-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(150, 74, 80)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    color-scheme: dark;
  }

  :root:not([data-theme]) input:is([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]) {
    --pico-form-element-focus-color: var(--pico-primary-focus);
  }

  :root:not([data-theme]) details summary[role="button"].contrast:not(.outline):after, :root:not([data-theme]) [aria-busy="true"]:not(input, select, textarea).contrast:is(button, [type="submit"], [type="button"], [type="reset"], [role="button"]):not(.outline):before {
    filter: brightness(0);
  }
}

[data-theme="dark"] {
  --pico-background-color: #13171f;
  --pico-color: #c2c7d0;
  --pico-text-selection-color: #909ebe30;
  --pico-muted-color: #7b8495;
  --pico-muted-border-color: #202632;
  --pico-primary: #909ebe;
  --pico-primary-background: #525f7a;
  --pico-primary-border: var(--pico-primary-background);
  --pico-primary-underline: #909ebe80;
  --pico-primary-hover: #b0b9d0;
  --pico-primary-hover-background: #5d6b89;
  --pico-primary-hover-border: var(--pico-primary-hover-background);
  --pico-primary-hover-underline: var(--pico-primary-hover);
  --pico-primary-focus: #909ebe60;
  --pico-primary-inverse: #fff;
  --pico-secondary: #969eaf;
  --pico-secondary-background: #525f7a;
  --pico-secondary-border: var(--pico-secondary-background);
  --pico-secondary-underline: #969eaf80;
  --pico-secondary-hover: #b3b9c5;
  --pico-secondary-hover-background: #5d6b89;
  --pico-secondary-hover-border: var(--pico-secondary-hover-background);
  --pico-secondary-hover-underline: var(--pico-secondary-hover);
  --pico-secondary-focus: #909ebe40;
  --pico-secondary-inverse: #fff;
  --pico-contrast: #dfe3eb;
  --pico-contrast-background: #eff1f4;
  --pico-contrast-border: var(--pico-contrast-background);
  --pico-contrast-underline: #dfe3eb80;
  --pico-contrast-hover: #fff;
  --pico-contrast-hover-background: #fff;
  --pico-contrast-hover-border: var(--pico-contrast-hover-background);
  --pico-contrast-hover-underline: var(--pico-contrast-hover);
  --pico-contrast-focus: #cfd5e240;
  --pico-contrast-inverse: #000;
  --pico-box-shadow: .0145rem .029rem .174rem #07090c04, .0335rem .067rem .402rem #07090c06, .0625rem .125rem .75rem #07090c08, .1125rem .225rem 1.35rem #07090c09, .2085rem .417rem 2.502rem #07090c0b, .5rem 1rem 6rem #07090c0f, 0 0 0 .0625rem #07090c04;
  --pico-h1-color: #f0f1f3;
  --pico-h2-color: #e0e3e7;
  --pico-h3-color: #c2c7d0;
  --pico-h4-color: #b3b9c5;
  --pico-h5-color: #a4acba;
  --pico-h6-color: #8891a4;
  --pico-mark-background-color: #014063;
  --pico-mark-color: #fff;
  --pico-ins-color: #62af9a;
  --pico-del-color: #ce7e7b;
  --pico-blockquote-border-color: var(--pico-muted-border-color);
  --pico-blockquote-footer-color: var(--pico-muted-color);
  --pico-button-box-shadow: 0 0 0 #0000;
  --pico-button-hover-box-shadow: 0 0 0 #0000;
  --pico-table-border-color: var(--pico-muted-border-color);
  --pico-table-row-stripped-background-color: #6f78870a;
  --pico-code-background-color: #1a1f28;
  --pico-code-color: #8891a4;
  --pico-code-kbd-background-color: var(--pico-color);
  --pico-code-kbd-color: var(--pico-background-color);
  --pico-form-element-background-color: #1c212c;
  --pico-form-element-selected-background-color: #2a3140;
  --pico-form-element-border-color: #2a3140;
  --pico-form-element-color: #e0e3e7;
  --pico-form-element-placeholder-color: #8891a4;
  --pico-form-element-active-background-color: #1a1f28;
  --pico-form-element-active-border-color: var(--pico-primary-border);
  --pico-form-element-focus-color: var(--pico-primary-border);
  --pico-form-element-disabled-opacity: .5;
  --pico-form-element-invalid-border-color: #964a50;
  --pico-form-element-invalid-active-border-color: #b7403b;
  --pico-form-element-invalid-focus-color: var(--pico-form-element-invalid-active-border-color);
  --pico-form-element-valid-border-color: #2a7b6f;
  --pico-form-element-valid-active-border-color: #16896a;
  --pico-form-element-valid-focus-color: var(--pico-form-element-valid-active-border-color);
  --pico-switch-background-color: #333c4e;
  --pico-switch-checked-background-color: var(--pico-primary-background);
  --pico-switch-color: #fff;
  --pico-switch-thumb-box-shadow: 0 0 0 #0000;
  --pico-range-border-color: #202632;
  --pico-range-active-border-color: #2a3140;
  --pico-range-thumb-border-color: var(--pico-background-color);
  --pico-range-thumb-color: var(--pico-secondary-background);
  --pico-range-thumb-active-color: var(--pico-primary-background);
  --pico-accordion-border-color: var(--pico-muted-border-color);
  --pico-accordion-active-summary-color: var(--pico-primary-hover);
  --pico-accordion-close-summary-color: var(--pico-color);
  --pico-accordion-open-summary-color: var(--pico-muted-color);
  --pico-card-background-color: #181c25;
  --pico-card-border-color: var(--pico-card-background-color);
  --pico-card-box-shadow: var(--pico-box-shadow);
  --pico-card-sectioning-background-color: #1a1f28;
  --pico-dropdown-background-color: #181c25;
  --pico-dropdown-border-color: #202632;
  --pico-dropdown-box-shadow: var(--pico-box-shadow);
  --pico-dropdown-color: var(--pico-color);
  --pico-dropdown-hover-background-color: #202632;
  --pico-loading-spinner-opacity: .5;
  --pico-modal-overlay-background-color: #08090abf;
  --pico-progress-background-color: #202632;
  --pico-progress-color: var(--pico-primary-background);
  --pico-tooltip-background-color: var(--pico-contrast-background);
  --pico-tooltip-color: var(--pico-contrast-inverse);
  --pico-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(42, 123, 111)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --pico-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(150, 74, 80)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

[data-theme="dark"] input:is([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [type="file"]) {
  --pico-form-element-focus-color: var(--pico-primary-focus);
}

[data-theme="dark"] details summary[role="button"].contrast:not(.outline):after, [data-theme="dark"] [aria-busy="true"]:not(input, select, textarea).contrast:is(button, [type="submit"], [type="button"], [type="reset"], [role="button"]):not(.outline):before {
  filter: brightness(0);
}

progress, [type="checkbox"], [type="radio"], [type="range"] {
  accent-color: var(--pico-primary);
}

*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: var(--pico-background-color);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  font-size: var(--pico-font-size);
  line-height: var(--pico-line-height);
  font-family: var(--pico-font-family);
  text-underline-offset: var(--pico-text-underline-offset);
  text-rendering: optimizelegibility;
  overflow-wrap: break-word;
  tab-size: 4;
}

body {
  width: 100%;
  margin: 0;
}

main {
  display: block;
}

body > header, body > main, body > footer {
  padding-block: var(--pico-block-spacing-vertical);
}

section {
  margin-bottom: var(--pico-block-spacing-vertical);
}

.container, .container-fluid {
  padding-right: var(--pico-spacing);
  padding-left: var(--pico-spacing);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container {
    max-width: 510px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 700px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 950px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1200px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1450px;
  }
}

.grid {
  grid-column-gap: var(--pico-grid-column-gap);
  grid-row-gap: var(--pico-grid-row-gap);
  grid-template-columns: 1fr;
  display: grid;
}

@media (width >= 768px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
  }
}

.grid > * {
  min-width: 0;
}

.overflow-auto {
  overflow: auto;
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

address, blockquote, dl, ol, p, pre, table, ul {
  margin-top: 0;
  margin-bottom: var(--pico-typography-spacing-vertical);
  color: var(--pico-color);
  font-style: normal;
  font-weight: var(--pico-font-weight);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--pico-typography-spacing-vertical);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  font-size: var(--pico-font-size);
  line-height: var(--pico-line-height);
  font-family: var(--pico-font-family);
}

h1 {
  --pico-color: var(--pico-h1-color);
}

h2 {
  --pico-color: var(--pico-h2-color);
}

h3 {
  --pico-color: var(--pico-h3-color);
}

h4 {
  --pico-color: var(--pico-h4-color);
}

h5 {
  --pico-color: var(--pico-h5-color);
}

h6 {
  --pico-color: var(--pico-h6-color);
}

:where(article, address, blockquote, dl, figure, form, ol, p, pre, table, ul) ~ :is(h1, h2, h3, h4, h5, h6) {
  margin-top: var(--pico-typography-spacing-top);
}

p, hgroup {
  margin-bottom: var(--pico-typography-spacing-vertical);
}

hgroup > * {
  margin-top: 0;
  margin-bottom: 0;
}

hgroup > :not(:first-child):last-child {
  --pico-color: var(--pico-muted-color);
  --pico-font-weight: unset;
  font-size: 1rem;
}

:where(ol, ul) li {
  margin-bottom: calc(var(--pico-typography-spacing-vertical) * .25);
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
  margin-top: calc(var(--pico-typography-spacing-vertical) * .25);
}

ul li {
  list-style: square;
}

mark {
  background-color: var(--pico-mark-background-color);
  color: var(--pico-mark-color);
  vertical-align: baseline;
  padding: .125rem .25rem;
}

blockquote {
  margin: var(--pico-typography-spacing-vertical) 0;
  padding: var(--pico-spacing);
  border-right: none;
  border-left: .25rem solid var(--pico-blockquote-border-color);
  border-inline-start: .25rem solid var(--pico-blockquote-border-color);
  border-inline-end: none;
  display: block;
}

blockquote footer {
  margin-top: calc(var(--pico-typography-spacing-vertical) * .5);
  color: var(--pico-blockquote-footer-color);
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

ins {
  color: var(--pico-ins-color);
  text-decoration: none;
}

del {
  color: var(--pico-del-color);
}

::selection {
  background-color: var(--pico-text-selection-color);
}

:where(a:not([role="button"])), [role="link"] {
  --pico-color: var(--pico-primary);
  --pico-background-color: transparent;
  --pico-underline: var(--pico-primary-underline);
  background-color: var(--pico-background-color);
  color: var(--pico-color);
  -webkit-text-decoration: var(--pico-text-decoration);
  text-decoration: var(--pico-text-decoration);
  -webkit-text-decoration-color: var(--pico-underline);
  text-decoration-color: var(--pico-underline);
  text-underline-offset: .125em;
  transition: background-color var(--pico-transition), color var(--pico-transition), text-decoration var(--pico-transition), box-shadow var(--pico-transition);
  outline: none;
}

:where(a:not([role="button"])):is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [role="link"]:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-primary-hover);
  --pico-underline: var(--pico-primary-hover-underline);
  --pico-text-decoration: underline;
}

:where(a:not([role="button"])):focus-visible, [role="link"]:focus-visible {
  box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-primary-focus);
}

:where(a:not([role="button"])).secondary, [role="link"].secondary {
  --pico-color: var(--pico-secondary);
  --pico-underline: var(--pico-secondary-underline);
}

:where(a:not([role="button"])).secondary:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [role="link"].secondary:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-secondary-hover);
  --pico-underline: var(--pico-secondary-hover-underline);
}

:where(a:not([role="button"])).contrast, [role="link"].contrast {
  --pico-color: var(--pico-contrast);
  --pico-underline: var(--pico-contrast-underline);
}

:where(a:not([role="button"])).contrast:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [role="link"].contrast:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-contrast-hover);
  --pico-underline: var(--pico-contrast-hover-underline);
}

a[role="button"] {
  display: inline-block;
}

button {
  text-transform: none;
  margin: 0;
  font-family: inherit;
  overflow: visible;
}

button, [type="submit"], [type="reset"], [type="button"] {
  -webkit-appearance: button;
}

button, [type="submit"], [type="reset"], [type="button"], [role="button"] {
  --pico-background-color: var(--pico-primary-background);
  --pico-border-color: var(--pico-primary-border);
  --pico-color: var(--pico-primary-inverse);
  --pico-box-shadow: var(--pico-button-box-shadow, 0 0 0 #0000);
  padding: var(--pico-form-element-spacing-vertical) var(--pico-form-element-spacing-horizontal);
  border: var(--pico-border-width) solid var(--pico-border-color);
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-background-color);
  box-shadow: var(--pico-box-shadow);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  font-size: 1rem;
  line-height: var(--pico-line-height);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--pico-transition), border-color var(--pico-transition), color var(--pico-transition), box-shadow var(--pico-transition);
  outline: none;
  text-decoration: none;
}

[type="file"]::file-selector-button {
  --pico-background-color: var(--pico-primary-background);
  --pico-border-color: var(--pico-primary-border);
  --pico-color: var(--pico-primary-inverse);
  --pico-box-shadow: var(--pico-button-box-shadow, 0 0 0 #0000);
  padding: var(--pico-form-element-spacing-vertical) var(--pico-form-element-spacing-horizontal);
  border: var(--pico-border-width) solid var(--pico-border-color);
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-background-color);
  box-shadow: var(--pico-box-shadow);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  font-size: 1rem;
  line-height: var(--pico-line-height);
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--pico-transition), border-color var(--pico-transition), color var(--pico-transition), box-shadow var(--pico-transition);
  outline: none;
  text-decoration: none;
}

button[aria-current]:not([aria-current="false"]), button:is(:hover, :active, :focus), [type="submit"][aria-current]:not([aria-current="false"]), [type="submit"]:is(:hover, :active, :focus), [type="reset"][aria-current]:not([aria-current="false"]), [type="reset"]:is(:hover, :active, :focus), [type="button"][aria-current]:not([aria-current="false"]), [type="button"]:is(:hover, :active, :focus), [role="button"][aria-current]:not([aria-current="false"]), [role="button"]:is(:hover, :active, :focus) {
  --pico-background-color: var(--pico-primary-hover-background);
  --pico-border-color: var(--pico-primary-hover-border);
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000);
  --pico-color: var(--pico-primary-inverse);
}

[type="file"]::file-selector-button:is() {
  --pico-background-color: var(--pico-primary-hover-background);
  --pico-border-color: var(--pico-primary-hover-border);
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000);
  --pico-color: var(--pico-primary-inverse);
}

[type="file"]::file-selector-button:is(:hover, :active, :focus) {
  --pico-background-color: var(--pico-primary-hover-background);
  --pico-border-color: var(--pico-primary-hover-border);
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000);
  --pico-color: var(--pico-primary-inverse);
}

button:focus, button[aria-current]:not([aria-current="false"]):focus, [type="submit"]:focus, [type="submit"][aria-current]:not([aria-current="false"]):focus, [type="reset"]:focus, [type="reset"][aria-current]:not([aria-current="false"]):focus, [type="button"]:focus, [type="button"][aria-current]:not([aria-current="false"]):focus, [role="button"]:focus, [role="button"][aria-current]:not([aria-current="false"]):focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-primary-focus);
}

[type="submit"], [type="reset"], [type="button"] {
  margin-bottom: var(--pico-spacing);
}

:is(button, [type="submit"], [type="button"], [role="button"]).secondary, [type="reset"] {
  --pico-background-color: var(--pico-secondary-background);
  --pico-border-color: var(--pico-secondary-border);
  --pico-color: var(--pico-secondary-inverse);
  cursor: pointer;
}

[type="file"]::file-selector-button {
  --pico-background-color: var(--pico-secondary-background);
  --pico-border-color: var(--pico-secondary-border);
  --pico-color: var(--pico-secondary-inverse);
  cursor: pointer;
}

:is(button, [type="submit"], [type="button"], [role="button"]).secondary:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [type="reset"]:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-background-color: var(--pico-secondary-hover-background);
  --pico-border-color: var(--pico-secondary-hover-border);
  --pico-color: var(--pico-secondary-inverse);
}

[type="file"]::file-selector-button:is(:hover, :active, :focus) {
  --pico-background-color: var(--pico-secondary-hover-background);
  --pico-border-color: var(--pico-secondary-hover-border);
  --pico-color: var(--pico-secondary-inverse);
}

:is(button, [type="submit"], [type="button"], [role="button"]).secondary:focus, :is(button, [type="submit"], [type="button"], [role="button"]).secondary[aria-current]:not([aria-current="false"]):focus, [type="reset"]:focus, [type="reset"][aria-current]:not([aria-current="false"]):focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-secondary-focus);
}

[type="file"]::file-selector-button:focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-secondary-focus);
}

[type="file"]::file-selector-button:is():focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-secondary-focus);
}

:is(button, [type="submit"], [type="button"], [role="button"]).contrast {
  --pico-background-color: var(--pico-contrast-background);
  --pico-border-color: var(--pico-contrast-border);
  --pico-color: var(--pico-contrast-inverse);
}

:is(button, [type="submit"], [type="button"], [role="button"]).contrast:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-background-color: var(--pico-contrast-hover-background);
  --pico-border-color: var(--pico-contrast-hover-border);
  --pico-color: var(--pico-contrast-inverse);
}

:is(button, [type="submit"], [type="button"], [role="button"]).contrast:focus, :is(button, [type="submit"], [type="button"], [role="button"]).contrast[aria-current]:not([aria-current="false"]):focus {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-contrast-focus);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline, [type="reset"].outline {
  --pico-background-color: transparent;
  --pico-color: var(--pico-primary);
  --pico-border-color: var(--pico-primary);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [type="reset"].outline:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-background-color: transparent;
  --pico-color: var(--pico-primary-hover);
  --pico-border-color: var(--pico-primary-hover);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline.secondary, [type="reset"].outline {
  --pico-color: var(--pico-secondary);
  --pico-border-color: var(--pico-secondary);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline.secondary:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), [type="reset"].outline:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-secondary-hover);
  --pico-border-color: var(--pico-secondary-hover);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline.contrast {
  --pico-color: var(--pico-contrast);
  --pico-border-color: var(--pico-contrast);
}

:is(button, [type="submit"], [type="button"], [role="button"]).outline.contrast:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  --pico-color: var(--pico-contrast-hover);
  --pico-border-color: var(--pico-contrast-hover);
}

:where(button, [type="submit"], [type="reset"], [type="button"], [role="button"])[disabled], :where(fieldset[disabled]) :is(button, [type="submit"], [type="button"], [type="reset"], [role="button"]) {
  opacity: .5;
  pointer-events: none;
}

:where(table) {
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
  width: 100%;
}

th, td {
  padding: calc(var(--pico-spacing) / 2) var(--pico-spacing);
  border-bottom: var(--pico-border-width) solid var(--pico-table-border-color);
  background-color: var(--pico-background-color);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  text-align: left;
  text-align: start;
}

tfoot th, tfoot td {
  border-top: var(--pico-border-width) solid var(--pico-table-border-color);
  border-bottom: 0;
}

table.striped tbody tr:nth-child(odd) th, table.striped tbody tr:nth-child(odd) td {
  background-color: var(--pico-table-row-stripped-background-color);
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

:where(iframe) {
  border-style: none;
}

img {
  border-style: none;
  max-width: 100%;
  height: auto;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

pre, code, kbd, samp {
  font-size: .875em;
  font-family: var(--pico-font-family);
}

pre code {
  font-size: inherit;
  font-family: inherit;
}

pre {
  -ms-overflow-style: scrollbar;
  overflow: auto;
}

pre, code, kbd {
  border-radius: var(--pico-border-radius);
  background: var(--pico-code-background-color);
  color: var(--pico-code-color);
  font-weight: var(--pico-font-weight);
  line-height: initial;
}

code, kbd {
  padding: .375rem;
  display: inline-block;
}

pre {
  margin-bottom: var(--pico-spacing);
  display: block;
  overflow-x: auto;
}

pre > code {
  padding: var(--pico-spacing);
  line-height: var(--pico-line-height);
  background: none;
  display: block;
}

kbd {
  background-color: var(--pico-code-kbd-background-color);
  color: var(--pico-code-kbd-color);
  vertical-align: baseline;
}

figure {
  margin: 0;
  padding: 0;
  display: block;
}

figure figcaption {
  padding: calc(var(--pico-spacing) * .5) 0;
  color: var(--pico-muted-color);
}

hr {
  margin: var(--pico-typography-spacing-vertical) 0;
  border: 0;
  border-top: 1px solid var(--pico-muted-border-color);
  color: inherit;
  height: 0;
}

[hidden], template {
  display: none !important;
}

canvas {
  display: inline-block;
}

input, optgroup, select, textarea {
  font-size: 1rem;
  line-height: var(--pico-line-height);
  letter-spacing: inherit;
  margin: 0;
  font-family: inherit;
}

input {
  overflow: visible;
}

select {
  text-transform: none;
}

legend {
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-ms-expand {
  display: none;
}

[type="file"], [type="range"] {
  border-width: 0;
  padding: 0;
}

input:not([type="checkbox"], [type="radio"], [type="range"]) {
  height: calc(1rem * var(--pico-line-height)  + var(--pico-form-element-spacing-vertical) * 2 + var(--pico-border-width) * 2);
}

fieldset {
  margin: 0;
  margin-bottom: var(--pico-spacing);
  border: 0;
  width: 100%;
  padding: 0;
}

label, fieldset legend {
  margin-bottom: calc(var(--pico-spacing) * .375);
  color: var(--pico-color);
  font-weight: var(--pico-form-label-font-weight, var(--pico-font-weight));
  display: block;
}

fieldset legend {
  margin-bottom: calc(var(--pico-spacing) * .5);
}

input:not([type="checkbox"], [type="radio"]), button[type="submit"], select, textarea {
  width: 100%;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"]), select, textarea {
  -webkit-appearance: none;
  appearance: none;
  padding: var(--pico-form-element-spacing-vertical) var(--pico-form-element-spacing-horizontal);
}

input, select, textarea {
  --pico-background-color: var(--pico-form-element-background-color);
  --pico-border-color: var(--pico-form-element-border-color);
  --pico-color: var(--pico-form-element-color);
  --pico-box-shadow: none;
  border: var(--pico-border-width) solid var(--pico-border-color);
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-background-color);
  box-shadow: var(--pico-box-shadow);
  color: var(--pico-color);
  font-weight: var(--pico-font-weight);
  transition: background-color var(--pico-transition), border-color var(--pico-transition), color var(--pico-transition), box-shadow var(--pico-transition);
  outline: none;
}

input:not([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [readonly]):is(:active, :focus), :where(select, textarea):not([readonly]):is(:active, :focus) {
  --pico-background-color: var(--pico-form-element-active-background-color);
}

input:not([type="submit"], [type="button"], [type="reset"], [role="switch"], [readonly]):is(:active, :focus), :where(select, textarea):not([readonly]):is(:active, :focus) {
  --pico-border-color: var(--pico-form-element-active-border-color);
}

input:not([type="submit"], [type="button"], [type="reset"], [type="range"], [type="file"], [readonly]):focus, :where(select, textarea):not([readonly]):focus {
  --pico-box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-form-element-focus-color);
}

input:not([type="submit"], [type="button"], [type="reset"])[disabled], select[disabled], textarea[disabled], label[aria-disabled="true"], :where(fieldset[disabled]) :is(input:not([type="submit"], [type="button"], [type="reset"]), select, textarea) {
  opacity: var(--pico-form-element-disabled-opacity);
  pointer-events: none;
}

label[aria-disabled="true"] input[disabled] {
  opacity: 1;
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"], [type="range"])[aria-invalid] {
  padding-left: var(--pico-form-element-spacing-horizontal);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-right: calc(var(--pico-form-element-spacing-horizontal)  + 1.5rem) !important;
  padding-inline-start: var(--pico-form-element-spacing-horizontal) !important;
  padding-inline-end: calc(var(--pico-form-element-spacing-horizontal)  + 1.5rem) !important;
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"], [type="range"])[aria-invalid="false"]:not(select) {
  background-image: var(--pico-icon-valid);
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"], [type="range"])[aria-invalid="true"]:not(select) {
  background-image: var(--pico-icon-invalid);
}

:where(input, select, textarea)[aria-invalid="false"] {
  --pico-border-color: var(--pico-form-element-valid-border-color);
}

:where(input, select, textarea)[aria-invalid="false"]:is(:active, :focus) {
  --pico-border-color: var(--pico-form-element-valid-active-border-color) !important;
}

:where(input, select, textarea)[aria-invalid="false"]:is(:active, :focus):not([type="checkbox"], [type="radio"]) {
  --pico-box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-form-element-valid-focus-color) !important;
}

:where(input, select, textarea)[aria-invalid="true"] {
  --pico-border-color: var(--pico-form-element-invalid-border-color);
}

:where(input, select, textarea)[aria-invalid="true"]:is(:active, :focus) {
  --pico-border-color: var(--pico-form-element-invalid-active-border-color) !important;
}

:where(input, select, textarea)[aria-invalid="true"]:is(:active, :focus):not([type="checkbox"], [type="radio"]) {
  --pico-box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-form-element-invalid-focus-color) !important;
}

[dir="rtl"] :where(input, select, textarea):not([type="checkbox"], [type="radio"]):is([aria-invalid], [aria-invalid="true"], [aria-invalid="false"]) {
  background-position: .75rem;
}

input::placeholder, textarea::placeholder, select:invalid {
  color: var(--pico-form-element-placeholder-color);
  opacity: 1;
}

input::-webkit-input-placeholder {
  color: var(--pico-form-element-placeholder-color);
  opacity: 1;
}

textarea::-webkit-input-placeholder {
  color: var(--pico-form-element-placeholder-color);
  opacity: 1;
}

input:not([type="checkbox"], [type="radio"]), select, textarea {
  margin-bottom: var(--pico-spacing);
}

select::-ms-expand {
  background-color: #0000;
  border: 0;
}

select:not([multiple], [size]) {
  padding-right: calc(var(--pico-form-element-spacing-horizontal)  + 1.5rem);
  padding-left: var(--pico-form-element-spacing-horizontal);
  background-image: var(--pico-icon-chevron);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-inline-start: var(--pico-form-element-spacing-horizontal);
  padding-inline-end: calc(var(--pico-form-element-spacing-horizontal)  + 1.5rem);
}

select[multiple] option:checked {
  background: var(--pico-form-element-selected-background-color);
  color: var(--pico-form-element-color);
}

[dir="rtl"] select:not([multiple], [size]) {
  background-position: .75rem;
}

textarea {
  resize: vertical;
  display: block;
}

textarea[aria-invalid] {
  --pico-icon-height: calc(1rem * var(--pico-line-height)  + var(--pico-form-element-spacing-vertical) * 2 + var(--pico-border-width) * 2);
  background-position: right .75rem top !important;
  background-size: 1rem var(--pico-icon-height) !important;
}

:where(input, select, textarea, fieldset, .grid) + small {
  margin-top: calc(var(--pico-spacing) * -.75);
  margin-bottom: var(--pico-spacing);
  color: var(--pico-muted-color);
  width: 100%;
  display: block;
}

:where(input, select, textarea, fieldset, .grid)[aria-invalid="false"] + small {
  color: var(--pico-ins-color);
}

:where(input, select, textarea, fieldset, .grid)[aria-invalid="true"] + small {
  color: var(--pico-del-color);
}

label > :where(input, select, textarea) {
  margin-top: calc(var(--pico-spacing) * .25);
}

label:has([type="checkbox"], [type="radio"]) {
  cursor: pointer;
  width: fit-content;
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  border-width: var(--pico-border-width);
  vertical-align: middle;
  cursor: pointer;
  margin-top: -.125em;
  width: 1.25em;
  height: 1.25em;
  margin-inline-end: .5em;
}

[type="checkbox"]::-ms-check {
  display: none;
}

[type="radio"]::-ms-check {
  display: none;
}

[type="checkbox"]:checked, [type="checkbox"]:checked:active, [type="checkbox"]:checked:focus, [type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --pico-background-color: var(--pico-primary-background);
  --pico-border-color: var(--pico-primary-border);
  background-image: var(--pico-icon-checkbox);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="checkbox"] ~ label, [type="radio"] ~ label {
  cursor: pointer;
  margin-bottom: 0;
  display: inline-block;
}

[type="checkbox"] ~ label:not(:last-of-type), [type="radio"] ~ label:not(:last-of-type) {
  margin-inline-end: 1em;
}

[type="checkbox"]:indeterminate {
  --pico-background-color: var(--pico-primary-background);
  --pico-border-color: var(--pico-primary-border);
  background-image: var(--pico-icon-minus);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="radio"] {
  border-radius: 50%;
}

[type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --pico-background-color: var(--pico-primary-inverse);
  background-image: none;
  border-width: .35em;
}

[type="checkbox"][role="switch"] {
  --pico-background-color: var(--pico-switch-background-color);
  --pico-color: var(--pico-switch-color);
  border: var(--pico-border-width) solid var(--pico-border-color);
  background-color: var(--pico-background-color);
  border-radius: 1.25em;
  width: 2.25em;
  height: 1.25em;
  line-height: 1.25em;
}

[type="checkbox"][role="switch"]:not([aria-invalid]) {
  --pico-border-color: var(--pico-switch-background-color);
}

[type="checkbox"][role="switch"]:before {
  aspect-ratio: 1;
  background-color: var(--pico-color);
  box-shadow: var(--pico-switch-thumb-box-shadow);
  content: "";
  border-radius: 50%;
  height: 100%;
  transition: margin .1s ease-in-out;
  display: block;
}

[type="checkbox"][role="switch"]:focus {
  --pico-background-color: var(--pico-switch-background-color);
  --pico-border-color: var(--pico-switch-background-color);
}

[type="checkbox"][role="switch"]:checked {
  --pico-background-color: var(--pico-switch-checked-background-color);
  --pico-border-color: var(--pico-switch-checked-background-color);
  background-image: none;
}

[type="checkbox"][role="switch"]:checked:before {
  margin-inline-start: 1em;
}

[type="checkbox"][role="switch"][disabled] {
  --pico-background-color: var(--pico-border-color);
}

[type="checkbox"][aria-invalid="false"]:checked, [type="checkbox"][aria-invalid="false"]:checked:active, [type="checkbox"][aria-invalid="false"]:checked:focus, [type="checkbox"][role="switch"][aria-invalid="false"]:checked, [type="checkbox"][role="switch"][aria-invalid="false"]:checked:active, [type="checkbox"][role="switch"][aria-invalid="false"]:checked:focus {
  --pico-background-color: var(--pico-form-element-valid-border-color);
}

[type="checkbox"]:checked[aria-invalid="true"], [type="checkbox"]:checked:active[aria-invalid="true"], [type="checkbox"]:checked:focus[aria-invalid="true"], [type="checkbox"][role="switch"]:checked[aria-invalid="true"], [type="checkbox"][role="switch"]:checked:active[aria-invalid="true"], [type="checkbox"][role="switch"]:checked:focus[aria-invalid="true"] {
  --pico-background-color: var(--pico-form-element-invalid-border-color);
}

[type="checkbox"][aria-invalid="false"]:checked, [type="checkbox"][aria-invalid="false"]:checked:active, [type="checkbox"][aria-invalid="false"]:checked:focus, [type="radio"][aria-invalid="false"]:checked, [type="radio"][aria-invalid="false"]:checked:active, [type="radio"][aria-invalid="false"]:checked:focus, [type="checkbox"][role="switch"][aria-invalid="false"]:checked, [type="checkbox"][role="switch"][aria-invalid="false"]:checked:active, [type="checkbox"][role="switch"][aria-invalid="false"]:checked:focus {
  --pico-border-color: var(--pico-form-element-valid-border-color);
}

[type="checkbox"]:checked[aria-invalid="true"], [type="checkbox"]:checked:active[aria-invalid="true"], [type="checkbox"]:checked:focus[aria-invalid="true"], [type="radio"]:checked[aria-invalid="true"], [type="radio"]:checked:active[aria-invalid="true"], [type="radio"]:checked:focus[aria-invalid="true"], [type="checkbox"][role="switch"]:checked[aria-invalid="true"], [type="checkbox"][role="switch"]:checked:active[aria-invalid="true"], [type="checkbox"][role="switch"]:checked:focus[aria-invalid="true"] {
  --pico-border-color: var(--pico-form-element-invalid-border-color);
}

[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

[type="color"]::-moz-focus-inner {
  padding: 0;
}

[type="color"]::-webkit-color-swatch {
  border-radius: calc(var(--pico-border-radius) * .5);
  border: 0;
}

[type="color"]::-moz-color-swatch {
  border-radius: calc(var(--pico-border-radius) * .5);
  border: 0;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"]):is([type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"]) {
  --pico-icon-position: .75rem;
  --pico-icon-width: 1rem;
  padding-right: calc(var(--pico-icon-width)  + var(--pico-icon-position));
  background-image: var(--pico-icon-date);
  background-position: center right var(--pico-icon-position);
  background-size: var(--pico-icon-width) auto;
  background-repeat: no-repeat;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="time"] {
  background-image: var(--pico-icon-time);
}

[type="date"]::-webkit-calendar-picker-indicator {
  width: var(--pico-icon-width);
  margin-right: calc(var(--pico-icon-width) * -1);
  margin-left: var(--pico-icon-position);
  opacity: 0;
}

[type="datetime-local"]::-webkit-calendar-picker-indicator {
  width: var(--pico-icon-width);
  margin-right: calc(var(--pico-icon-width) * -1);
  margin-left: var(--pico-icon-position);
  opacity: 0;
}

[type="month"]::-webkit-calendar-picker-indicator {
  width: var(--pico-icon-width);
  margin-right: calc(var(--pico-icon-width) * -1);
  margin-left: var(--pico-icon-position);
  opacity: 0;
}

[type="time"]::-webkit-calendar-picker-indicator {
  width: var(--pico-icon-width);
  margin-right: calc(var(--pico-icon-width) * -1);
  margin-left: var(--pico-icon-position);
  opacity: 0;
}

[type="week"]::-webkit-calendar-picker-indicator {
  width: var(--pico-icon-width);
  margin-right: calc(var(--pico-icon-width) * -1);
  margin-left: var(--pico-icon-position);
  opacity: 0;
}

@-moz-document url-prefix() {
  [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"] {
    padding-right: var(--pico-form-element-spacing-horizontal) !important;
    background-image: none !important;
  }
}

[dir="rtl"] :is([type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"]) {
  text-align: right;
}

[type="file"] {
  --pico-color: var(--pico-muted-color);
  margin-left: calc(var(--pico-outline-width) * -1);
  padding: calc(var(--pico-form-element-spacing-vertical) * .5) 0;
  padding-left: var(--pico-outline-width);
  background: none;
  border: 0;
  border-radius: 0;
}

[type="file"]::file-selector-button {
  margin-right: calc(var(--pico-spacing) / 2);
  padding: calc(var(--pico-form-element-spacing-vertical) * .5) var(--pico-form-element-spacing-horizontal);
}

[type="file"]:is(:hover, :active, :focus)::file-selector-button {
  --pico-background-color: var(--pico-secondary-hover-background);
  --pico-border-color: var(--pico-secondary-hover-border);
}

[type="file"]:focus::file-selector-button {
  --pico-box-shadow: var(--pico-button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--pico-outline-width) var(--pico-secondary-focus);
}

[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  width: 100%;
  height: 1.25rem;
}

[type="range"]::-webkit-slider-runnable-track {
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-range-border-color);
  transition: background-color var(--pico-transition), box-shadow var(--pico-transition);
  width: 100%;
  height: .375rem;
}

[type="range"]::-moz-range-track {
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-range-border-color);
  transition: background-color var(--pico-transition), box-shadow var(--pico-transition);
  width: 100%;
  height: .375rem;
}

[type="range"]::-ms-track {
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-range-border-color);
  transition: background-color var(--pico-transition), box-shadow var(--pico-transition);
  width: 100%;
  height: .375rem;
}

[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid var(--pico-range-thumb-border-color);
  background-color: var(--pico-range-thumb-color);
  cursor: pointer;
  transition: background-color var(--pico-transition), transform var(--pico-transition);
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -.4375rem;
}

[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  border: 2px solid var(--pico-range-thumb-border-color);
  background-color: var(--pico-range-thumb-color);
  cursor: pointer;
  transition: background-color var(--pico-transition), transform var(--pico-transition);
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -.4375rem;
}

[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  border: 2px solid var(--pico-range-thumb-border-color);
  background-color: var(--pico-range-thumb-color);
  cursor: pointer;
  transition: background-color var(--pico-transition), transform var(--pico-transition);
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -.4375rem;
}

[type="range"]:active, [type="range"]:focus-within {
  --pico-range-border-color: var(--pico-range-active-border-color);
  --pico-range-thumb-color: var(--pico-range-thumb-active-color);
}

[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.25);
}

[type="range"]:active::-moz-range-thumb {
  transform: scale(1.25);
}

[type="range"]:active::-ms-thumb {
  transform: scale(1.25);
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"] {
  background-image: var(--pico-icon-search);
  background-position: center left calc(var(--pico-form-element-spacing-horizontal)  + .125rem);
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-inline-start: calc(var(--pico-form-element-spacing-horizontal)  + 1.75rem);
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid] {
  background-position: 1.125rem, right .75rem center;
  padding-inline-start: calc(var(--pico-form-element-spacing-horizontal)  + 1.75rem) !important;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid="false"] {
  background-image: var(--pico-icon-search), var(--pico-icon-valid);
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid="true"] {
  background-image: var(--pico-icon-search), var(--pico-icon-invalid);
}

[dir="rtl"] :where(input):not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"] {
  background-position: right 1.125rem center;
}

[dir="rtl"] :where(input):not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid] {
  background-position: right 1.125rem center, .75rem;
}

details {
  margin-bottom: var(--pico-spacing);
  display: block;
}

details summary {
  cursor: pointer;
  transition: color var(--pico-transition);
  line-height: 1rem;
  list-style-type: none;
}

details summary:not([role]) {
  color: var(--pico-accordion-close-summary-color);
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::marker {
  display: none;
}

details summary::-moz-list-bullet {
  list-style-type: none;
}

details summary:after {
  float: right;
  background-image: var(--pico-icon-chevron);
  content: "";
  transition: transform var(--pico-transition);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1rem;
  height: 1rem;
  margin-inline-start: calc(var(--pico-spacing, 1rem) * .5);
  display: block;
  transform: rotate(-90deg);
}

details summary:focus {
  outline: none;
}

details summary:focus:not([role]) {
  color: var(--pico-accordion-active-summary-color);
}

details summary:focus-visible:not([role]) {
  outline: var(--pico-outline-width) solid var(--pico-primary-focus);
  outline-offset: calc(var(--pico-spacing, 1rem) * .5);
  color: var(--pico-primary);
}

details summary[role="button"] {
  text-align: left;
  width: 100%;
}

details summary[role="button"]:after {
  height: calc(1rem * var(--pico-line-height, 1.5));
}

details[open] > summary {
  margin-bottom: var(--pico-spacing);
}

details[open] > summary:not([role]):not(:focus) {
  color: var(--pico-accordion-open-summary-color);
}

details[open] > summary:after {
  transform: rotate(0);
}

[dir="rtl"] details summary {
  text-align: right;
}

[dir="rtl"] details summary:after {
  float: left;
  background-position: 0;
}

article {
  margin-bottom: var(--pico-block-spacing-vertical);
  padding: var(--pico-block-spacing-vertical) var(--pico-block-spacing-horizontal);
  border-radius: var(--pico-border-radius);
  background: var(--pico-card-background-color);
  box-shadow: var(--pico-card-box-shadow);
}

article > header, article > footer {
  margin-right: calc(var(--pico-block-spacing-horizontal) * -1);
  margin-left: calc(var(--pico-block-spacing-horizontal) * -1);
  padding: calc(var(--pico-block-spacing-vertical) * .66) var(--pico-block-spacing-horizontal);
  background-color: var(--pico-card-sectioning-background-color);
}

article > header {
  margin-top: calc(var(--pico-block-spacing-vertical) * -1);
  margin-bottom: var(--pico-block-spacing-vertical);
  border-bottom: var(--pico-border-width) solid var(--pico-card-border-color);
  border-top-right-radius: var(--pico-border-radius);
  border-top-left-radius: var(--pico-border-radius);
}

article > footer {
  margin-top: var(--pico-block-spacing-vertical);
  margin-bottom: calc(var(--pico-block-spacing-vertical) * -1);
  border-top: var(--pico-border-width) solid var(--pico-card-border-color);
  border-bottom-right-radius: var(--pico-border-radius);
  border-bottom-left-radius: var(--pico-border-radius);
}

details.dropdown {
  border-bottom: none;
  position: relative;
}

details.dropdown summary:after, details.dropdown > button:after, details.dropdown > a:after {
  height: calc(1rem * var(--pico-line-height, 1.5));
  float: right;
  background-image: var(--pico-icon-chevron);
  content: "";
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1rem;
  margin-inline-start: .25rem;
  display: block;
  transform: rotate(0)translateX(.2rem);
}

nav details.dropdown {
  margin-bottom: 0;
}

details.dropdown summary:not([role]) {
  height: calc(1rem * var(--pico-line-height)  + var(--pico-form-element-spacing-vertical) * 2 + var(--pico-border-width) * 2);
  padding: var(--pico-form-element-spacing-vertical) var(--pico-form-element-spacing-horizontal);
  border: var(--pico-border-width) solid var(--pico-form-element-border-color);
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-form-element-background-color);
  color: var(--pico-form-element-placeholder-color);
  line-height: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--pico-transition), border-color var(--pico-transition), color var(--pico-transition), box-shadow var(--pico-transition);
}

details.dropdown summary:not([role]):active, details.dropdown summary:not([role]):focus {
  border-color: var(--pico-form-element-active-border-color);
  background-color: var(--pico-form-element-active-background-color);
}

details.dropdown summary:not([role]):focus {
  box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-form-element-focus-color);
}

details.dropdown summary:not([role]):focus-visible {
  outline: none;
}

details.dropdown summary:not([role])[aria-invalid="false"] {
  --pico-form-element-border-color: var(--pico-form-element-valid-border-color);
  --pico-form-element-active-border-color: var(--pico-form-element-valid-focus-color);
  --pico-form-element-focus-color: var(--pico-form-element-valid-focus-color);
}

details.dropdown summary:not([role])[aria-invalid="true"] {
  --pico-form-element-border-color: var(--pico-form-element-invalid-border-color);
  --pico-form-element-active-border-color: var(--pico-form-element-invalid-focus-color);
  --pico-form-element-focus-color: var(--pico-form-element-invalid-focus-color);
}

nav details.dropdown {
  margin: calc(var(--pico-nav-element-spacing-vertical) * -1) 0;
  display: inline;
}

nav details.dropdown summary:after {
  transform: rotate(0)translateX(0);
}

nav details.dropdown summary:not([role]) {
  height: calc(1rem * var(--pico-line-height)  + var(--pico-nav-link-spacing-vertical) * 2);
  padding: calc(var(--pico-nav-link-spacing-vertical)  - var(--pico-border-width) * 2) var(--pico-nav-link-spacing-horizontal);
}

nav details.dropdown summary:not([role]):focus-visible {
  box-shadow: 0 0 0 var(--pico-outline-width) var(--pico-primary-focus);
}

details.dropdown summary + ul {
  z-index: 99;
  margin: 0;
  margin-top: var(--pico-outline-width);
  border: var(--pico-border-width) solid var(--pico-dropdown-border-color);
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-dropdown-background-color);
  box-shadow: var(--pico-dropdown-box-shadow);
  color: var(--pico-dropdown-color);
  white-space: nowrap;
  opacity: 0;
  transition: opacity var(--pico-transition), transform 0s ease-in-out 1s;
  flex-direction: column;
  width: 100%;
  min-width: fit-content;
  padding: 0;
  display: flex;
  position: absolute;
  left: 0;
}

details.dropdown summary + ul[dir="rtl"] {
  left: auto;
  right: 0;
}

details.dropdown summary + ul li {
  padding: calc(var(--pico-form-element-spacing-vertical) * .5) var(--pico-form-element-spacing-horizontal);
  width: 100%;
  margin-bottom: 0;
  list-style: none;
}

details.dropdown summary + ul li:first-of-type {
  margin-top: calc(var(--pico-form-element-spacing-vertical) * .5);
}

details.dropdown summary + ul li:last-of-type {
  margin-bottom: calc(var(--pico-form-element-spacing-vertical) * .5);
}

details.dropdown summary + ul li a {
  margin: calc(var(--pico-form-element-spacing-vertical) * -.5) calc(var(--pico-form-element-spacing-horizontal) * -1);
  padding: calc(var(--pico-form-element-spacing-vertical) * .5) var(--pico-form-element-spacing-horizontal);
  color: var(--pico-dropdown-color);
  text-overflow: ellipsis;
  border-radius: 0;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

details.dropdown summary + ul li a:hover, details.dropdown summary + ul li a:focus, details.dropdown summary + ul li a:active, details.dropdown summary + ul li a:focus-visible, details.dropdown summary + ul li a[aria-current]:not([aria-current="false"]) {
  background-color: var(--pico-dropdown-hover-background-color);
}

details.dropdown summary + ul li label {
  width: 100%;
}

details.dropdown summary + ul li:has(label):hover {
  background-color: var(--pico-dropdown-hover-background-color);
}

details.dropdown[open] summary {
  margin-bottom: 0;
}

details.dropdown[open] summary + ul {
  opacity: 1;
  transition: opacity var(--pico-transition), transform 0s ease-in-out 0s;
  transform: scaleY(1);
}

details.dropdown[open] summary:before {
  z-index: 1;
  content: "";
  cursor: default;
  background: none;
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  inset: 0;
}

label > details.dropdown {
  margin-top: calc(var(--pico-spacing) * .25);
}

[role="search"], [role="group"] {
  margin-bottom: var(--pico-spacing);
  border-radius: var(--pico-border-radius);
  box-shadow: var(--pico-group-box-shadow, 0 0 0 #0000);
  vertical-align: middle;
  transition: box-shadow var(--pico-transition);
  width: 100%;
  display: inline-flex;
  position: relative;
}

[role="search"] > *, [role="search"] input:not([type="checkbox"], [type="radio"]), [role="search"] select, [role="group"] > *, [role="group"] input:not([type="checkbox"], [type="radio"]), [role="group"] select {
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

[role="search"] > :not(:first-child), [role="search"] input:not([type="checkbox"], [type="radio"]):not(:first-child), [role="search"] select:not(:first-child), [role="group"] > :not(:first-child), [role="group"] input:not([type="checkbox"], [type="radio"]):not(:first-child), [role="group"] select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

[role="search"] > :not(:last-child), [role="search"] input:not([type="checkbox"], [type="radio"]):not(:last-child), [role="search"] select:not(:last-child), [role="group"] > :not(:last-child), [role="group"] input:not([type="checkbox"], [type="radio"]):not(:last-child), [role="group"] select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

[role="search"] > :focus, [role="search"] input:not([type="checkbox"], [type="radio"]):focus, [role="search"] select:focus, [role="group"] > :focus, [role="group"] input:not([type="checkbox"], [type="radio"]):focus, [role="group"] select:focus {
  z-index: 2;
}

[role="search"] button:not(:first-child), [role="search"] [type="submit"]:not(:first-child), [role="search"] [type="reset"]:not(:first-child), [role="search"] [type="button"]:not(:first-child), [role="search"] [role="button"]:not(:first-child), [role="search"] input:not([type="checkbox"], [type="radio"]):not(:first-child), [role="search"] select:not(:first-child), [role="group"] button:not(:first-child), [role="group"] [type="submit"]:not(:first-child), [role="group"] [type="reset"]:not(:first-child), [role="group"] [type="button"]:not(:first-child), [role="group"] [role="button"]:not(:first-child), [role="group"] input:not([type="checkbox"], [type="radio"]):not(:first-child), [role="group"] select:not(:first-child) {
  margin-left: calc(var(--pico-border-width) * -1);
}

[role="search"] button, [role="search"] [type="submit"], [role="search"] [type="reset"], [role="search"] [type="button"], [role="search"] [role="button"], [role="group"] button, [role="group"] [type="submit"], [role="group"] [type="reset"], [role="group"] [type="button"], [role="group"] [role="button"] {
  width: auto;
}

@supports selector(:has(*)) {
  [role="search"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus), [role="group"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) {
    --pico-group-box-shadow: var(--pico-group-box-shadow-focus-with-button);
  }

  [role="search"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) input:not([type="checkbox"], [type="radio"]), [role="search"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) select, [role="group"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) input:not([type="checkbox"], [type="radio"]), [role="group"]:has(button:focus, [type="submit"]:focus, [type="button"]:focus, [role="button"]:focus) select {
    border-color: #0000;
  }

  [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus), [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) {
    --pico-group-box-shadow: var(--pico-group-box-shadow-focus-with-input);
  }

  [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) button, [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [type="submit"], [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [type="button"], [role="search"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [role="button"], [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) button, [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [type="submit"], [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [type="button"], [role="group"]:has(input:not([type="submit"], [type="button"]):focus, select:focus) [role="button"] {
    --pico-button-box-shadow: 0 0 0 var(--pico-border-width) var(--pico-primary-border);
    --pico-button-hover-box-shadow: 0 0 0 var(--pico-border-width) var(--pico-primary-hover-border);
  }

  [role="search"] button:focus, [role="search"] [type="submit"]:focus, [role="search"] [type="reset"]:focus, [role="search"] [type="button"]:focus, [role="search"] [role="button"]:focus, [role="group"] button:focus, [role="group"] [type="submit"]:focus, [role="group"] [type="reset"]:focus, [role="group"] [type="button"]:focus, [role="group"] [role="button"]:focus {
    box-shadow: none;
  }
}

[role="search"] > :first-child {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
}

[role="search"] > :last-child {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

[aria-busy="true"]:not(input, select, textarea, html) {
  white-space: nowrap;
}

[aria-busy="true"]:not(input, select, textarea, html):before {
  background-image: var(--pico-icon-loading);
  content: "";
  vertical-align: -.125em;
  background-repeat: no-repeat;
  background-size: 1em;
  width: 1em;
  height: 1em;
  display: inline-block;
}

[aria-busy="true"]:not(input, select, textarea, html):not(:empty):before {
  margin-inline-end: calc(var(--pico-spacing) * .5);
}

[aria-busy="true"]:not(input, select, textarea, html):empty {
  text-align: center;
}

button[aria-busy="true"], [type="submit"][aria-busy="true"], [type="button"][aria-busy="true"], [type="reset"][aria-busy="true"], [role="button"][aria-busy="true"], a[aria-busy="true"] {
  pointer-events: none;
}

:root {
  --pico-scrollbar-width: 0px;
}

dialog {
  z-index: 999;
  width: inherit;
  height: inherit;
  backdrop-filter: var(--pico-modal-overlay-backdrop-filter);
  background-color: var(--pico-modal-overlay-background-color);
  color: var(--pico-color);
  border: 0;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  padding: 0;
  display: flex;
  position: fixed;
  inset: 0;
}

dialog article {
  max-height: calc(100vh - var(--pico-spacing) * 2);
  margin: var(--pico-spacing);
  width: 100%;
  overflow: auto;
}

@media (width >= 576px) {
  dialog article {
    max-width: 510px;
  }
}

@media (width >= 768px) {
  dialog article {
    max-width: 700px;
  }
}

dialog article > header > * {
  margin-bottom: 0;
}

dialog article > header .close, dialog article > header :is(a, button)[rel="prev"] {
  margin: 0;
  margin-left: var(--pico-spacing);
  float: right;
  padding: 0;
}

dialog article > footer {
  text-align: right;
}

dialog article > footer button, dialog article > footer [role="button"] {
  margin-bottom: 0;
}

dialog article > footer button:not(:first-of-type), dialog article > footer [role="button"]:not(:first-of-type) {
  margin-left: calc(var(--pico-spacing) * .5);
}

dialog article .close, dialog article :is(a, button)[rel="prev"] {
  margin-top: calc(var(--pico-spacing) * -1);
  margin-bottom: var(--pico-spacing);
  background-image: var(--pico-icon-close);
  opacity: .5;
  transition: opacity var(--pico-transition);
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 1rem;
  border: none;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  display: block;
}

dialog article .close:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus), dialog article :is(a, button)[rel="prev"]:is([aria-current]:not([aria-current="false"]), :hover, :active, :focus) {
  opacity: 1;
}

dialog:not([open]), dialog[open="false"] {
  display: none;
}

.modal-is-open {
  padding-right: var(--pico-scrollbar-width, 0px);
  pointer-events: none;
  touch-action: none;
  overflow: hidden;
}

.modal-is-open dialog {
  pointer-events: auto;
  touch-action: auto;
}

:where(.modal-is-opening, .modal-is-closing) dialog, :where(.modal-is-opening, .modal-is-closing) dialog > article {
  animation-duration: .2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

:where(.modal-is-opening, .modal-is-closing) dialog {
  animation-name: modal-overlay;
  animation-duration: .8s;
}

:where(.modal-is-opening, .modal-is-closing) dialog > article {
  animation-name: modal;
  animation-delay: .2s;
}

.modal-is-closing dialog, .modal-is-closing dialog > article {
  animation-direction: reverse;
  animation-delay: 0s;
}

@keyframes modal-overlay {
  from {
    backdrop-filter: none;
    background-color: #0000;
  }
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
}

:where(nav li):before {
  float: left;
  content: "​";
}

nav, nav ul {
  display: flex;
}

nav {
  justify-content: space-between;
  overflow: visible;
}

nav ol, nav ul {
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

nav ol:first-of-type, nav ul:first-of-type {
  margin-left: calc(var(--pico-nav-element-spacing-horizontal) * -1);
}

nav ol:last-of-type, nav ul:last-of-type {
  margin-right: calc(var(--pico-nav-element-spacing-horizontal) * -1);
}

nav li {
  padding: var(--pico-nav-element-spacing-vertical) var(--pico-nav-element-spacing-horizontal);
  margin: 0;
  display: inline-block;
}

nav li :where(a, [role="link"]) {
  margin: calc(var(--pico-nav-link-spacing-vertical) * -1) calc(var(--pico-nav-link-spacing-horizontal) * -1);
  padding: var(--pico-nav-link-spacing-vertical) var(--pico-nav-link-spacing-horizontal);
  border-radius: var(--pico-border-radius);
  display: inline-block;
}

nav li :where(a, [role="link"]):not(:hover) {
  text-decoration: none;
}

nav li button, nav li [role="button"], nav li [type="button"], nav li input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"]), nav li select {
  margin-right: inherit;
  margin-bottom: 0;
  margin-left: inherit;
  padding: calc(var(--pico-nav-link-spacing-vertical)  - var(--pico-border-width) * 2) var(--pico-nav-link-spacing-horizontal);
  height: auto;
}

nav[aria-label="breadcrumb"] {
  justify-content: start;
  align-items: center;
}

nav[aria-label="breadcrumb"] ul li:not(:first-child) {
  margin-inline-start: var(--pico-nav-link-spacing-horizontal);
}

nav[aria-label="breadcrumb"] ul li a {
  margin: calc(var(--pico-nav-link-spacing-vertical) * -1) 0;
  margin-inline-start: calc(var(--pico-nav-link-spacing-horizontal) * -1);
}

nav[aria-label="breadcrumb"] ul li:not(:last-child):after {
  width: calc(var(--pico-nav-link-spacing-horizontal) * 4);
  margin: 0 calc(var(--pico-nav-link-spacing-horizontal) * -1);
  content: var(--pico-nav-breadcrumb-divider);
  color: var(--pico-muted-color);
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  position: absolute;
}

nav[aria-label="breadcrumb"] a[aria-current]:not([aria-current="false"]) {
  color: inherit;
  pointer-events: none;
  background-color: #0000;
  text-decoration: none;
}

aside nav, aside ol, aside ul, aside li {
  display: block;
}

aside li {
  padding: calc(var(--pico-nav-element-spacing-vertical) * .5) var(--pico-nav-element-spacing-horizontal);
}

aside li a {
  display: block;
}

aside li [role="button"] {
  margin: inherit;
}

[dir="rtl"] nav[aria-label="breadcrumb"] ul li:not(:last-child) :after {
  content: "\\";
}

progress {
  vertical-align: baseline;
  -webkit-appearance: none;
  appearance: none;
  margin-bottom: calc(var(--pico-spacing) * .5);
  border-radius: var(--pico-border-radius);
  background-color: var(--pico-progress-background-color);
  color: var(--pico-progress-color);
  border: 0;
  width: 100%;
  height: .5rem;
  display: inline-block;
  overflow: hidden;
}

progress::-webkit-progress-bar {
  border-radius: var(--pico-border-radius);
  background: none;
}

progress[value]::-webkit-progress-value {
  background-color: var(--pico-progress-color);
  transition: inline-size var(--pico-transition);
}

progress::-moz-progress-bar {
  background-color: var(--pico-progress-color);
}

@media (prefers-reduced-motion: no-preference) {
  progress:indeterminate {
    background: var(--pico-progress-background-color) linear-gradient(to right, var(--pico-progress-color) 30%, var(--pico-progress-background-color) 30%) top left / 150% 150% no-repeat;
    animation: 1s linear infinite progress-indeterminate;
  }

  progress:indeterminate[value]::-webkit-progress-value {
    background-color: #0000;
  }

  progress:indeterminate::-moz-progress-bar {
    background-color: #0000;
  }

  [dir="rtl"] progress:indeterminate {
    animation-direction: reverse;
  }
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:not(a, button, input) {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

[data-tooltip][data-placement="top"]:before, [data-tooltip][data-placement="top"]:after, [data-tooltip]:before, [data-tooltip]:after {
  z-index: 99;
  border-radius: var(--pico-border-radius);
  background: var(--pico-tooltip-background-color);
  content: attr(data-tooltip);
  color: var(--pico-tooltip-color);
  font-style: normal;
  font-weight: var(--pico-font-weight);
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  padding: .25rem .5rem;
  font-size: .875rem;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -.25rem);
}

[data-tooltip][data-placement="top"]:after, [data-tooltip]:after {
  content: "";
  color: var(--pico-tooltip-background-color);
  background-color: #0000;
  border-top: .3rem solid;
  border-left: .3rem solid #0000;
  border-right: .3rem solid #0000;
  border-radius: 0;
  padding: 0;
  transform: translate(-50%);
}

[data-tooltip][data-placement="bottom"]:before, [data-tooltip][data-placement="bottom"]:after {
  top: 100%;
  bottom: auto;
  transform: translate(-50%, .25rem);
}

[data-tooltip][data-placement="bottom"]:after {
  border: .3rem solid #0000;
  border-bottom-color: currentColor;
  transform: translate(-50%, -.3rem);
}

[data-tooltip][data-placement="left"]:before, [data-tooltip][data-placement="left"]:after {
  inset: 50% 100% auto auto;
  transform: translate(-.25rem, -50%);
}

[data-tooltip][data-placement="left"]:after {
  border: .3rem solid #0000;
  border-left-color: currentColor;
  transform: translate(.3rem, -50%);
}

[data-tooltip][data-placement="right"]:before, [data-tooltip][data-placement="right"]:after {
  inset: 50% auto auto 100%;
  transform: translate(.25rem, -50%);
}

[data-tooltip][data-placement="right"]:after {
  border: .3rem solid #0000;
  border-right-color: currentColor;
  transform: translate(-.3rem, -50%);
}

[data-tooltip]:focus:before, [data-tooltip]:focus:after, [data-tooltip]:hover:before, [data-tooltip]:hover:after {
  opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
  [data-tooltip]:focus:before, [data-tooltip]:focus:after, [data-tooltip]:hover:before, [data-tooltip]:hover:after {
    --pico-tooltip-slide-to: translate(-50%, -.25rem);
    opacity: 0;
    animation-name: tooltip-slide;
    animation-duration: .2s;
    animation-fill-mode: forwards;
    transform: translate(-50%, .75rem);
  }

  [data-tooltip]:focus:after, [data-tooltip]:hover:after {
    --pico-tooltip-caret-slide-to: translate(-50%, 0rem);
    animation-name: tooltip-caret-slide;
    transform: translate(-50%, -.25rem);
  }

  [data-tooltip][data-placement="bottom"]:focus:before, [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:hover:before, [data-tooltip][data-placement="bottom"]:hover:after {
    --pico-tooltip-slide-to: translate(-50%, .25rem);
    animation-name: tooltip-slide;
    transform: translate(-50%, -.75rem);
  }

  [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:hover:after {
    --pico-tooltip-caret-slide-to: translate(-50%, -.3rem);
    animation-name: tooltip-caret-slide;
    transform: translate(-50%, -.5rem);
  }

  [data-tooltip][data-placement="left"]:focus:before, [data-tooltip][data-placement="left"]:focus:after, [data-tooltip][data-placement="left"]:hover:before, [data-tooltip][data-placement="left"]:hover:after {
    --pico-tooltip-slide-to: translate(-.25rem, -50%);
    animation-name: tooltip-slide;
    transform: translate(.75rem, -50%);
  }

  [data-tooltip][data-placement="left"]:focus:after, [data-tooltip][data-placement="left"]:hover:after {
    --pico-tooltip-caret-slide-to: translate(.3rem, -50%);
    animation-name: tooltip-caret-slide;
    transform: translate(.05rem, -50%);
  }

  [data-tooltip][data-placement="right"]:focus:before, [data-tooltip][data-placement="right"]:focus:after, [data-tooltip][data-placement="right"]:hover:before, [data-tooltip][data-placement="right"]:hover:after {
    --pico-tooltip-slide-to: translate(.25rem, -50%);
    animation-name: tooltip-slide;
    transform: translate(-.75rem, -50%);
  }

  [data-tooltip][data-placement="right"]:focus:after, [data-tooltip][data-placement="right"]:hover:after {
    --pico-tooltip-caret-slide-to: translate(-.3rem, -50%);
    animation-name: tooltip-caret-slide;
    transform: translate(-.05rem, -50%);
  }
}

@keyframes tooltip-slide {
  to {
    transform: var(--pico-tooltip-slide-to);
    opacity: 1;
  }
}

@keyframes tooltip-caret-slide {
  50% {
    opacity: 0;
  }

  to {
    transform: var(--pico-tooltip-caret-slide-to);
    opacity: 1;
  }
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"], [disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
}

[dir="rtl"] {
  direction: rtl;
}

@media (prefers-reduced-motion: reduce) {
  :not([aria-busy="true"]), :not([aria-busy="true"]):before, :not([aria-busy="true"]):after {
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    animation-delay: -1ms !important;
  }
}

main {
  min-height: 90vh;
}

header {
  margin-top: 100vh;
}

header:after {
  content: " ";
  clear: both;
}

header .gradient {
  z-index: -1;
  background-color: none;
  opacity: 1;
  animation: 1s ease-in-out gradient-animation;
  animation-delay: calc(var(--sh) * -1s);
  background-image: linear-gradient(217deg, #002b39, #002b3900 70.71%), linear-gradient(127deg, #7a3200, #7a320000 70.71%), linear-gradient(336deg, #f9f871, #f9f87100 70.71%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  animation-iteration-count: 1;
  animation-play-state: paused;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

@keyframes gradient-animation {
  to {
    opacity: .4;
  }
}

header .logo {
  z-index: -1;
  opacity: 1;
  animation: 1s linear logo-aninmation;
  animation-delay: calc(var(--sh) * -1s);
  width: 60vmin;
  height: 60vmin;
  animation-iteration-count: 1;
  animation-play-state: paused;
  animation-fill-mode: both;
  position: fixed;
  top: calc(50vh - 30vmin);
  left: calc(50vw - 30vmin);
  transform: translate3d(0, 0, 0);
}

header .logo svg path {
  fill: #f0f1f3;
}

header .logo svg path#_180 {
  fill: #36454f;
}

header .logo svg path#_100 {
  filter: drop-shadow(0 0 15px #36454f);
}

@keyframes logo-aninmation {
  to {
    opacity: 0;
    width: 200vw;
    height: 200vw;
    top: -200vw;
    left: -50vw;
  }
}

header .bg-image {
  z-index: -1;
  object-position: center center;
  object-fit: cover;
  opacity: 1;
  animation: 1s ease-in-out bg-image-aninmation;
  animation-delay: calc(var(--sh) * -1s);
  width: 100vw;
  height: 100vh;
  animation-iteration-count: 1;
  animation-play-state: paused;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

@keyframes bg-image-aninmation {
  to {
    opacity: .5;
  }
}

header .cover-image {
  z-index: -1;
  object-position: center center;
  object-fit: cover;
  opacity: 1;
  filter: drop-shadow(0 0 15px #36454f);
  animation: .85s linear cover-image-aninmation;
  animation-delay: calc(var(--sh) * -1s);
  width: 60vmin;
  height: 60vmin;
  animation-iteration-count: 1;
  animation-play-state: paused;
  animation-fill-mode: both;
  position: fixed;
  top: calc(50vh - 30vmin);
  left: calc(50vw - 30vmin);
  transform: translate3d(0, 0, 0);
}

@keyframes cover-image-aninmation {
  to {
    opacity: 0;
    width: 100vmin;
    height: 100vmin;
    top: calc(50vh - 50vmin);
    left: calc(50vw - 50vmin);
  }
}

header .intro {
  z-index: -1;
  text-align: center;
  opacity: 1;
  animation: .8s linear intro-animation;
  animation-delay: calc(var(--sh) * -1s);
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: .5em;
  animation-iteration-count: 1;
  animation-play-state: paused;
  animation-fill-mode: both;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: visible;
  transform: translate3d(0, 0, 0);
}

@keyframes intro-animation {
  to {
    opacity: 0;
    width: 500vw;
    height: 200vmin;
    top: -200vmin;
    left: -200vw;
  }
}

header .intro h1 {
  line-break: loose;
  text-shadow: 0 0 20px #36454f;
  animation: .8s linear intro-h1-animation;
  animation-delay: calc(var(--sh) * -1s);
  font-size: 10vmin;
  font-weight: 900;
  line-height: 8vmin;
  animation-iteration-count: 1;
  animation-play-state: paused;
  animation-fill-mode: both;
}

@keyframes intro-h1-animation {
  to {
    letter-spacing: .3em;
    color: #36454f;
    text-shadow: 0 0 20px #36454f;
    font-size: 50vmin;
    line-height: 20vmin;
  }
}

header .progress {
  animation: 1s linear progress-animation;
  animation-delay: calc(var(--sc) * -1s);
  background-color: #ff0;
  width: 0;
  height: 3px;
  animation-iteration-count: 1;
  animation-play-state: paused;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes progress-animation {
  to {
    background-color: #00f;
    width: 100%;
  }
}

footer {
  color: var(--pico-secondary);
}

footer .right {
  float: right;
}

footer a {
  padding-left: .5em;
}
/*# sourceMappingURL=index.90d23332.css.map */
