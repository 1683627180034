@mixin animate($animation, $duration, $method, $delay) {
    animation: $animation $duration $method;
    animation-delay: $delay;
    animation-play-state: paused;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

@mixin fullscreen() {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: translate3d(0,0,0);
    z-index: -1;
}

$color-gradient-1: #002b39;
$color-gradient-2: #7a3200;
$color-gradient-3: #f9f871;

$color-header-foreground: rgb(240, 241, 243);
$color-header-shadow: #36454f;

header {
    //padding: 0;
    margin-top: 100vh;

    &:after {
        content: " ";
        clear: both;
    }

    .gradient {
        @include fullscreen();

        background-color: none;
        background-image: linear-gradient(
                217deg,
                color.change($color-gradient-1, $alpha: 1),
                color.change($color-gradient-1, $alpha: 0) 70.71%
            ),
            linear-gradient(
                127deg,
                color.change($color-gradient-2, $alpha: 1),
                color.change($color-gradient-2, $alpha: 0) 70.71%
            ),
            linear-gradient(
                336deg,
                color.change($color-gradient-3, $alpha: 1),
                color.change($color-gradient-3, $alpha: 0) 70.71%
            );
        background-repeat: no-repeat;
        background-attachment: fixed;

        opacity: 1;
        @include animate(
            gradient-animation,
            1s,
            ease-in-out,
            calc(var(--sh) * -1s)
        );
        @include keyframes(gradient-animation) {
            to {
                opacity: 0.4;
            }
        }
    }

    .logo {
        position: fixed;
        height: 60vmin;
        width: 60vmin;
        top: calc(50vh - 30vmin);
        left: calc(50vw - 30vmin);
        transform: translate3d(0,0,0);
        z-index: -1;

        svg {
            path {
                fill: $color-header-foreground;
                &#_180 {
                    fill: $color-header-shadow;
                }
                &#_100 {
                    filter: drop-shadow(0px 0px 15px $color-header-shadow);
                }
            }
        }

        opacity: 1;
        @include animate(logo-aninmation, 1s, linear, calc(var(--sh) * -1s));
        @include keyframes(logo-aninmation) {
            to {
                opacity: 0;
                height: 200vw;
                width: 200vw;
                top: -200vw;
                left: -50vw;
            }
        }
    }

    .bg-image {
        @include fullscreen();

        object-position: center center;
        object-fit: cover;

        opacity: 1;
        @include animate(
            bg-image-aninmation,
            1s,
            ease-in-out,
            calc(var(--sh) * -1s)
        );
        @include keyframes(bg-image-aninmation) {
            to {
                opacity: 0.5;
            }
        }
    }

    .cover-image {
        position: fixed;
        height: 60vmin;
        width: 60vmin;
        top: calc(50vh - 30vmin);
        left: calc(50vw - 30vmin);
        transform: translate3d(0,0,0);
        z-index: -1;

        object-position: center center;
        object-fit: cover;

        opacity: 1;
        filter: drop-shadow(0px 0px 15px $color-header-shadow);
        @include animate(
            cover-image-aninmation,
            0.85s,
            linear,
            calc(var(--sh) * -1s)
        );
        @include keyframes(cover-image-aninmation) {
            to {
                opacity: 0;
                height: 100vmin;
                width: 100vmin;
                top: calc(50vh - 50vmin);
                left: calc(50vw - 50vmin);
            }
        }
    }

    .intro {
        @include fullscreen();
        height: 100%;
        width: 100%;

        padding: 0.5em;
        overflow:visible;

        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;

        //filter: drop-shadow(0px 1px 3px $color-shadow-dark);

        opacity: 1;
        @include animate(intro-animation, .8s, linear, calc(var(--sh) * -1s));
        @include keyframes(intro-animation) {
            to {
                opacity: 0;
                top: -200vmin;
                left: -200vw;
                width: 500vw;
                height: 200vmin;
            }
        }

        h1 {
            font-weight: 900;
            font-size: 10vmin;
            line-height: 8vmin;
            line-break: loose;
            text-shadow: 0 0 20px $color-header-shadow;
            @include animate(intro-h1-animation, .8s, linear, calc(var(--sh) * -1s));
            @include keyframes(intro-h1-animation) {
                to {
                    font-size: 50vmin;
                    line-height: 20vmin;
                    letter-spacing: 0.3em;
                    color: $color-header-shadow;
                    text-shadow: 0 0 20px $color-header-shadow;
                }
            }
        }
    }

    .progress {
        position: fixed;
        top: 0;
        left: 0;
        width: 0px;
        height: 3px;

        background-color: yellow;
        @include animate(progress-animation, 1s, linear, calc(var(--sc) * -1s));
        @include keyframes(progress-animation) {
            to {
                background-color: blue;
                width: 100%;
            }
        }
    }
}
