@use "sass:color";
@use "sass:list";

@use "normalize-scss/sass/normalize";

@use "@picocss/pico/scss/pico" with (
    $theme-color: "slate"
);

main {
    min-height: 90vh;
}

@import "_header";
@import "_footer";
